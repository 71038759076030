html,
body {
  background-color: #FFFAF0;
  color: #110052;
  scroll-behavior:smooth
}

h2 {
  margin: 0;
}

 /* The hidden class is used for accessibility to give context for screen readers. */
.hidden {
  display: none;
}

.w100 {
  width: 100%;
}

.flex {
  display: flex;
}

.gap-8 {
  gap: 8px;
}

.flex-align-center {
  align-items: center;
}

.flex-justify-center {
  justify-content: center;
}

:root {
  --brand-blue: #322387;
  --brand-pink: #FF0082;

  --surface-000: #FFFAF0;
  --surface-100: #FFFFFF;

  --surface-bg-100: #FFFAF0;
  
  --shade-100: #faf2e7;
  --shade-200: #f2e7d8;
  --shade-300: #e0ccb7;
  
  --misc-white-extra-light: #ebebeb;
  --misc-white-light: #f1f1f1;
  --misc-white-medium: #fafafa;
  --misc-white-heavy: #ffffff;

  --misc-blue-extra-light: #d8d7dd;
  --misc-blue-light: #bcb9c9;
  --misc-blue-medium: #5f5388;
  --misc-blue-heavy: #110052;
  
  --header-links: var(--brand-blue);
  --alternative-section-bg: var(--brand-blue);
  --alternative-text-color: var(--misc-white-heavy);
  --alternative-header-color: var(--misc-white-heavy);
  --postfix-alternative-text-color: #FFFFFFCC;
  --normal-section-color: var(--misc-blue-heavy);
  --title: var(--brand-blue);
  --subtitle: var(--misc-blue-heavy);
  --button-text: var(--misc-white-heavy);
  --button-secondary: var(--brand-blue);
  --h2: var(--brand-blue);
  --alternative-secondary-button: var(--misc-white-heavy);
  --outline-button: var(--misc-blue-heavy);
  --faq-border-color: var(--misc-blue-heavy);
  --error-red: #ff2e00;
  --button-active-bg: #53469a;
  --button-active-border: var(--misc-white-heavy);
  --button-inactive-border: #847bb7;

  --input-border: var(--misc-blue-heavy);
  --input-bg: var(--surface-000);
  --input-shadow: 0px 4px 8px 0px rgba(125, 60, 0, 0.20) inset;

  --tile-shadow: 0px 12px 40px 8px rgba(6, 0, 31, 0.80);
  --tile-bg: var(--misc-white-heavy);

  --mobile-menu-bg: var(--surface-000);

  --testimonial-bg: #fffdfa;
  --testimonial-text-color: var(--brand-blue);
  --testimonial-shadow: 0px 4px 8px 0px rgba(125, 60, 0, 0.20);
  --testimonial-fade: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgb(255, 250, 240) 100%
  );

  --outline-border: #675a8b;
  --outline-focused: #11005212;
  --primary-focused: #b80074;
  --outline-alternative-focused: #37296e;

  --footer-outline-border: #FFFFFFCC;
  --footer-outline-focused: #FFFFFF29;

  --input-default-border: 1px solid #61517f;
  --input-focus-border: 1px solid #625280;
  --input-active-border: 1px solid #ff0082;
  --input-error-border: 1px solid #ff2e00;
  --input-error-color: #CE2500;
  --input-placeholder-text-color: #655787;
  --input-text-color: #110052;
  --input-default-bg: #faf2e7;
  --input-focus-bg: #f2e7d8;
  --input-active-bg: #f2e7d8;

  --logo-fingers-filter: brightness(0) saturate(100%) invert(21%) sepia(73%)
  saturate(1018%) hue-rotate(221deg) brightness(90%) contrast(119%);

  --logo-text-filter: brightness(0) saturate(100%) invert(17%) sepia(90%) saturate(5654%) hue-rotate(321deg) brightness(99%) contrast(110%);

  --empty-state-text: var(--misc-blue-heavy);
  --empty-state-secondary: #110052A3;

  --apply-now-filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(49deg) brightness(104%) contrast(101%);
  --img-filter: invert(11%) sepia(48%) saturate(7280%) hue-rotate(250deg) brightness(79%) contrast(91%);

  --stories-border: #7D3C003D;

  --timeline-border: #7D3C003D;
  --timeline-anchor-active: #110052; 
  --timeline-anchor: #110052A3;
  --timeline-item-bg: #7D3C000A;
  --timeline-item-border: #7D3C003D;
  --timeline-badge: #FF0082;
  --timeline-badge-text: #FFFFFF;

  --donate-button-icon: invert(1);
  --donate-icon-filters: invert(11%) sepia(48%) saturate(7280%) hue-rotate(250deg) brightness(79%) contrast(91%);
  --donation-item-bg-selected:#11005212;
  --donation-item-border: #110052a3;

  --donate-complete-h1: #322387;
  
  --link-color: #CD0068;
  
  background: var(--surface-000);
  color: var(--misc-blue-heavy);
}

body.dark {
  --brand-pink: #FF99CD;

  --surface-000: #110052;
  --surface-100: #D3CDEA;

  --surface-bg-100: #30216A;
  
  --shade-100: #0f0048;
  --shade-200: #0c003a;
  --shade-300: #080026;

  --header-links: var(--misc-white-heavy);
  --alternative-section-bg: var(--surface-000);
  --alternative-text-color: var(--misc-white-heavy);
  --alternative-header-color: var(--misc-white-heavy);
  --outline-button: var(--misc-white-heavy);
  --normal-section-color: var(--misc-white-heavy);

  --title: var(--misc-white-heavy);
  --subtitle: var(--misc-white-heavy);
  --button-text: var(--misc-blue-heavy);
  --button-secondary: var(--misc-white-heavy);

  --h2: var(--misc-white-heavy);
  --faq-border-color: var(--misc-white-heavy);

  --button-active-bg: #37296e;
  --button-active-border: var(--misc-white-heavy);
  --button-inactive-border: #706697;

  --input-border: var(--misc-white-heavy);
  --input-bg: #0f0048;
  --input-shadow: 0px 4px 8px 0px #06001F inset, 0px 2px 4px 0px rgba(255, 255, 255, 0.30) inset;

  --tile-shadow: 0px 12px 40px 8px rgba(6, 0, 31, 0.80);
  --tile-bg: #30216a;

  --footer-outline-border: #FFFFFFCC;
  --footer-outline-focused: #FFFFFF29;

  --mobile-menu-bg: var(--misc-blue-heavy);

  --outline-border: #FFFFFF;
  --outline-focused: #FFFFFF29;
  --primary-focused: #c674af;

  --input-default-border: 1px solid #d1cdd9;
  --input-focus-border: 1px solid #d1cdd9;
  --input-active-border: 1px solid #ff99cd;
  --input-error-border: 1px solid #ff8f77;
  --input-error-color: #ff8f77;
  --input-placeholder-text-color: #cfccda;
  --input-text-color: #ffffff;
  --input-default-bg: #0f0048;
  --input-focus-bg: #0c003a;
  --input-active-bg: #0c003a;

  --logo-fingers-filter: brightness(0) saturate(100%) invert(99%) sepia(2%) saturate(0%) hue-rotate(199deg) brightness(117%) contrast(100%);
  --logo-text-filter: brightness(0) saturate(100%) invert(93%) sepia(18%) saturate(4899%) hue-rotate(285deg) brightness(102%) contrast(101%);
  --img-filter: invert(1);

  --testimonial-bg: #30216a;
  --testimonial-text-color: #FFFFFF;
  --testimonial-shadow: 0px 4px 8px 0px rgba(6, 0, 31, 0.40);
  --testimonial-fade: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    #110052 100%
  );

  --stories-border: #FFFFFF40;

  --empty-state-text: var(--surface-100);
  --empty-state-secondary: var(--input-placeholder-text-color);

  --timeline-border: #FFFFFF66;
  --timeline-anchor-active: #FFFFFF; 
  --timeline-anchor: #FFFFFFCC;
  --timeline-item-bg: #06001F33;
  --timeline-item-border: #FFFFFF66;
  --timeline-badge: #FF99CD;
  --timeline-badge-text: #110052;

  --donate-button-icon: invert(7%) sepia(65%) saturate(7321%) hue-rotate(258deg) brightness(64%) contrast(114%);
  --donate-icon-filters: invert(1);
  --donation-item-bg-selected: #30216a;
  --donation-item-border: #fff;

  --donate-complete-h1: #FFF;

  --link-color: var(--brand-pink);

  background: var(--surface-000);
  color: var(--misc-white-heavy);
  
}

body.high-contrast {
  --brand-pink: #CD0068;
  --alternative-section-bg: var(--surface-000);
  --alternative-text-color: var(--misc-blue-heavy);
  --alternative-header-color: var(--brand-blue);
  --alternative-secondary-button: var(--misc-blue-heavy);
  --button-active-bg: #eee8e5;
  --button-active-border: var(--misc-blue-heavy);
  --button-inactive-border: #cfc8d0;

  --primary-focused: #6f005d;
  --outline-alternative-focused: #eee8e5;

  --outline-border: #110052;
  --input-default-border: 1px solid #110052;
  --input-focus-border: 1px solid #110052;
  --input-active-border: 1px solid #cd0068;
  --input-error-border: 1px solid #bb2200;
  --input-error-color: #bb2200;
  --input-placeholder-text-color: #655787;
  --input-text-color: #110052;
  --input-default-bg: #faf2e7;
  --input-focus-bg: #f2e7d8;
  --input-active-bg: #f2e7d8;

  --logo-text-filter: brightness(0) saturate(100%) invert(16%) sepia(91%) saturate(4700%) hue-rotate(319deg) brightness(78%) contrast(110%);
  --apply-now-filter: invert(11%) sepia(42%) saturate(4891%) hue-rotate(249deg) brightness(76%) contrast(128%);
  --img-filter: invert(11%) sepia(48%) saturate(7280%) hue-rotate(250deg) brightness(79%) contrast(91%);

  --footer-outline-border: #110052;
  --footer-outline-focused: #11005212;

  --tile-shadow: 0px 12px 40px 8px rgba(125, 60, 0, 0.30);

  --stories-border: #7D3C003D;

  --timeline-border: #7D3C003D;
  --timeline-anchor-active: #110052;
  --timeline-anchor: #110052A3;
  --timeline-item-bg: #7D3C000A;
  --timeline-item-border: #7D3C003D;
  --timeline-badge: #CD0068;
  --timeline-badge-text: #FFF;
  --link-color: var(--brand-pink);
}

#root {
  min-height: 100vh;
}

.alternative {
  background: var(--alternative-section-bg);
  color: var(--alternative-header-color);
}